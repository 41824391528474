import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl } from '@angular/platform-browser';

type Sanitization = 'html' | 'style' | 'script' | 'url' | 'resourceUrl';
/**
 * pipe disables Angular built-in sanitization and renders some url to the page, mostly used for rendering the casino games as trustful resource
 */
@Pipe({
  name: 'trusted',
  standalone: true
})
export class TrustedPipe implements PipeTransform {
  /**
   * The constructor
   */
  constructor(protected _sanitizer: DomSanitizer) {
  }

  /**
   * Transform
   *
   * @param value
   * @param type
   */
  transform(value: string, type?: Sanitization): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this._sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this._sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this._sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this._sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this._sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this._sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
